var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.state.loaded)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1):_c('v-container',{staticClass:"o-reviewers-list"},[_c('v-data-table',{attrs:{"headers":_vm.state.headers,"options":_vm.state.options,"items":_vm.state.items,"footer-props":{ itemsPerPageOptions: [20, 50, 100, 200, 500, 1000, 2000, 5000] },"expanded":_vm.state.expanded,"server-items-length":_vm.state.total,"single-expand":true,"show-expand":true,"loading":_vm.state.loading,"sort-by":_vm.state.options.sortBy},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)},"update:expanded":function($event){return _vm.$set(_vm.state, "expanded", $event)},"update:sortBy":function($event){return _vm.$set(_vm.state.options, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.state.options, "sortBy", $event)}},scopedSlots:_vm._u([{key:"item.lastName",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_vm._v(_vm._s(item.lastName))]}},{key:"item.abstractCount",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_vm._v(_vm._s(item.reviewed + item.leftToReview))]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-4",attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"o-reviewers-list--tables",attrs:{"outlined":""}},[((item.reviewed + item.leftToReview) === 0)?_c('v-toolbar',[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('panel.event.abstract.reviewersList.expandedItem.empty')))])],1):_c('v-card-text',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('panel.event.abstract.reviewersList.expandedItem.table.abstractsToReview')))]),_c('table',{staticStyle:{"min-width":"100%"}},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(_vm.$t('layout.misc.title')))]),_c('th',{staticStyle:{"width":"150px"}},[_vm._v(_vm._s(_vm.$t('layout.misc.panel')))]),_c('th',{staticStyle:{"width":"150px"}},[_vm._v(_vm._s(_vm.$t('layout.misc.type')))])])]),_c('tbody',[_vm._l((item.abstractsToReview),function(abstractToReview,$index){return [_c('v-dialog',{attrs:{"scrollable":"","max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('tr',_vm._g(_vm._b({},'tr',attrs,false),on),[_c('td',[_vm._v(_vm._s(abstractToReview.title))]),_c('td',{staticStyle:{"text-align":"center","max-width":"100px"}},[_vm._v(_vm._s(abstractToReview.sessionTopic.name))]),_c('td',{staticStyle:{"text-align":"center"}},[(abstractToReview.type === 1)?_c('v-chip',{attrs:{"color":"#709c1f","dark":""}},[_vm._v(_vm._s(_vm.getTypeName(abstractToReview.type)))]):(abstractToReview.type === 2)?_c('v-chip',{attrs:{"color":"#1f519c","dark":""}},[_vm._v(_vm._s(_vm.getTypeName(abstractToReview.type)))]):(abstractToReview.type === 3)?_c('v-chip',{attrs:{"color":"#dbae1a","dark":""}},[_vm._v(_vm._s(_vm.getTypeName(abstractToReview.type)))]):_vm._e()],1)])]}}],null,true),model:{value:(_vm.state.abstractDialog[$index]),callback:function ($$v) {_vm.$set(_vm.state.abstractDialog, $index, $$v)},expression:"state.abstractDialog[$index]"}},[_c('m-abstract-details',{attrs:{"abstract":abstractToReview.id},on:{"close":function($event){_vm.state.abstractDialog[$index] = false}}})],1)]})],2)]),_c('v-card-title',[_vm._v(_vm._s(_vm.$t('panel.event.abstract.reviewersList.expandedItem.table.reviewedAbstracts')))]),_c('table',{staticStyle:{"min-width":"100%"}},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(_vm.$t('layout.misc.title')))]),_c('th',{staticStyle:{"width":"150px"}},[_vm._v(_vm._s(_vm.$t('layout.misc.panel')))]),_c('th',{staticStyle:{"width":"150px"}},[_vm._v(_vm._s(_vm.$t('layout.misc.type')))]),_c('th',{staticStyle:{"width":"150px"}},[_vm._v(_vm._s(_vm.$t('panel.event.abstract.reviewersList.expandedItem.table.reviewDate')))]),_c('th',{attrs:{"width":"1"}},[_vm._v(_vm._s(_vm.$t('panel.event.abstract.reviewersList.expandedItem.table.averageRating')))])])]),_c('tbody',[_vm._l((item.reviews),function(review,$index){return [_c('v-dialog',{attrs:{"scrollable":"","max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('tr',_vm._g(_vm._b({},'tr',attrs,false),on),[_c('td',[_vm._v(_vm._s(review.abstract.title))]),_c('td',{staticStyle:{"text-align":"center","max-width":"100px"}},[_vm._v(_vm._s(review.abstract.sessionTopic.name))]),_c('td',{staticStyle:{"text-align":"center"}},[(review.abstract.type === 1)?_c('v-chip',{attrs:{"color":"#709c1f","dark":""}},[_vm._v(_vm._s(_vm.getTypeName(review.abstract.type)))]):(review.abstract.type === 2)?_c('v-chip',{attrs:{"color":"#1f519c","dark":""}},[_vm._v(_vm._s(_vm.getTypeName(review.abstract.type)))]):(review.abstract.type === 3)?_c('v-chip',{attrs:{"color":"#dbae1a","dark":""}},[_vm._v(_vm._s(_vm.getTypeName(review.abstract.type)))]):_vm._e()],1),_c('td',{staticStyle:{"text-align":"center"}},[(review.createdAt)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(review.createdAt,'DD-MM-YYYY')))]):_vm._e()]),_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(review.average))])])]}}],null,true),model:{value:(_vm.state.reviewedDialog[$index]),callback:function ($$v) {_vm.$set(_vm.state.reviewedDialog, $index, $$v)},expression:"state.reviewedDialog[$index]"}},[(review.abstract)?_c('m-abstract-details',{attrs:{"abstract":review.abstract.id},on:{"close":function($event){_vm.state.reviewedDialog[$index] = false}}}):_vm._e()],1)]})],2)])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }