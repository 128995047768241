

















































































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";
import useAuthPermission from "@/use/authPermissions";
import useAbstract from "@/use/abstract";

export default defineComponent({
  components: {
    MAbstractDetails: () =>
      import("@/components/molecules/abstracts/m-abstract-details.vue"),
  },

  setup(_, { root }) {
    const { getTypeName, getStatusName } = useAbstract({ root });

    const state = reactive({
      expanded: [] as any,
      headers: [
        { value: "lastName", text: root.$tc("layout.misc.lastName") },
        {
          value: "firstName",
          text: root.$tc("layout.misc.firstName"),
          sortable: false,
        },
        {
          value: "abstractCount",
          text: root.$tc("layout.misc.abstractCount"),
          align: "center",
          width: 1,
          sortable: false,
        },
        {
          value: "reviewed",
          text: root.$tc("layout.misc.reviewed"),
          align: "center",
          width: 1,
          sortable: false,
        },
        {
          value: "leftToReview",
          text: root.$tc("layout.misc.remainingToReview"),
          align: "center",
          width: 1,
          sortable: false,
        },
        { value: "actions", text: "", sortable: false, align: "right" },
        { text: "", value: "data-table-expand" },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      success: false,
      error: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ["lastName"],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      abstractDialog: {},
      reviewedDialog: {},
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`/event/${root.$route.params.id}/reviewer`, {
          params: { sortBy, sortDesc, page, itemsPerPage },
        })
        .then(({ data: { reviewers, total } }) => {
          state.empty = false;
          state.items = reviewers;
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);

    watch(() => state.options, fetchData, { deep: true });

    const { participantsManagement } = useAuthPermission({ root });

    return {
      state,
      fetchData,
      participantsManagement,

      getTypeName,
      getStatusName,
    };
  },
});
